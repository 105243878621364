const locationDetails = {
    seoul: {
        title: '법무법인 에이앤랩',
        address: '서울시 서초구 강남대로 337 13층',
        mapLink: 'https://naver.me/xuI1R0X8',
        lat: 37.495305,
        lng: 127.038701
    },
    incheon: {
        title: '법무법인 에이앤랩',
        address: '인천시 연수구 인천타워대로 323, 송도 센트로드 A동 2807호',
        mapLink: 'https://naver.me/FAQ5au5k',
        lat: 37.395755,
        lng: 126.633339
    },
    pyeongtaek: {
        title: '법무법인 에이앤랩',
        address: '경기도 평택시 평남로 1059(동삭동) 한동빌딩 4층',
        mapLink: 'https://map.naver.com/p/search/%EA%B2%BD%EA%B8%B0%EB%8F%84%20%ED%8F%89%ED%83%9D%EC%8B%9C%20%ED%8F%89%EB%82%A8%EB%A1%9C%201059/address/14147997.8184463,4440419.3514412,%EA%B2%BD%EA%B8%B0%EB%8F%84%20%ED%8F%89%ED%83%9D%EC%8B%9C%20%ED%8F%89%EB%82%A8%EB%A1%9C%201059,new?searchType=address&isCorrectAnswer=true&c=19.00,0,0,0,dh',
        lat: 37.009419,
        lng: 127.093636
    },
    suwon: {
        title: '법무법인 에이앤랩',
        address: '경기 수원시 영통구 광교중앙로248번길 7-2 원희캐슬법조타운 A동 602호',
        mapLink: 'https://naver.me/5s92QWgB',
        lat: 37.284314,
        lng: 127.044504
    },
    daegu: {
        title: '법무법인 에이앤랩',
        address: '대구광역시 달서구 장산남로 21, 706호',
        mapLink: 'https://map.naver.com/v5/entry/address/14307677.368083049,4280225.596392581,%EB%8C%80%EA%B5%AC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%8B%AC%EC%84%9C%EA%B5%AC%20%EC%9A%A9%EC%82%B0%EB%8F%99%20230-21,jibun?c=14307641.1447207,4280233.1913266,19,0,0,0,dh',
        lat: 35.829485,
        lng: 128.555645
    }
};

const isMobile = () => document.documentElement.clientWidth < 1024;

export { locationDetails, isMobile };
